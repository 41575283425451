/*
 * @LastEditors: xuexiumei
 * @LastEditTime: 2024-11-11 08:56:28
 */
export default {
  paneFillingTask: 'Form Filling Task Configuration',
  paneRiskConfiguration: 'Risk Territory Configuration',
  /* Form Filling Task Configuration */
  list: {
    treeTitle: 'Form Filling Plan',
    addBtn: 'Initiate Plan',
    deactivate: 'Deactivating',
    enable: 'Activating',
    completionRate: 'Completion Rate',
    startTime: 'Task Start/End Time',
    fillType: 'Form Name',
    fillCycle: 'Task Cycle',
    taskReceiveProjectName: 'Executing Objects',
    fillUserName: 'Filler',
    fillUserPhone: 'Contact Number',
    fillTime: 'Fill Date',
    status1: 'Completed',
    status2: 'Incomplete',
    search: {
      batchNumber: 'Task Batch',
      keyWords: 'Company/Project',
    },
    whetherOrNot: 'Whether',
    success: 'Success',
    canceled: 'Canceled',
  },
  add: {
    addAialogTitle: 'Initiate Form Filling Plan',
    editAialogTitle: 'Edit Form Filling Plan',
    tableCode: 'Form Name',
    cyclePropRequired: 'Task Cycle',
    highNum: 'High Risk',
    lowNum: 'Non-High Risk',
    detailList: 'Executing Objects',
    selectBtn: 'Select Company/Project',
    column_name: 'Company/Project/Name',
    _day: 'Day',
    _month: 'Month',
    _year: 'Year',
    dayOneOrder: 'Days/Times',
    rules: {
      tableCode: 'Please select the Form Name',
      taskType: 'Please select the Task Cycle',
      highNum: 'Please enter High Risk',
      lowNum: 'Please enter Non-High Risk',
      otherNum: 'Please enter Temporary Task Cycle',
      timeRange: 'Please select Temporary Date',
    },
    pleaseSelectData: 'Please select Executing Objects',
  },

  /* Risk Territory Configuration */
  handleRenewBtn: 'Update Risk Territory',
  handleAddBtn: 'Add Risk Territory',
  sort: 'Sort',
  sortholder: 'Default Auto Increment',
  riskCountryName: 'Country',
  riskCountryholder: 'Please select Country, can be fuzzy searched',
  riskRegionName: 'State/Province',
  riskRegionholder: 'Please select State/Province after selecting Country, can be fuzzy searched',
  universe: 'Universe',
}
