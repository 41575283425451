export default {
  paneFillingTask: '填报任务配置',
  paneRiskConfiguration: '风险属地配置',
  /* 填报任务配置--部分 */
  list: {
    treeTitle: '填报计划',
    addBtn: '发起计划',
    deactivate: '停用中',
    enable: '启用中',
    completionRate: '完成率',
    startTime: '任务起止时间',
    fillType: '表单名称',
    fillCycle: '任务周期',
    taskReceiveProjectName: '执行对象',
    fillUserName: '填报人',
    fillUserPhone: '联系电话',
    fillTime: '填报日期',
    status1: '已完成',
    status2: '未完成',
    search: {
      batchNumber: '任务批次',
      keyWords: '公司/项目',
    },
    whetherOrNot: '是否',
    success: '成功',
    canceled: '已取消',
  },
  add: {
    addAialogTitle: '发起填报计划',
    editAialogTitle: '编辑填报计划',
    tableCode: '表单名称',
    cyclePropRequired: '任务周期',
    highNum: '高风险',
    lowNum: '非高风险',
    detailList: '执行对象',
    selectBtn: '选择公司/项目',
    column_name: '公司项目/名称',
    _day: '天',
    _month: '月',
    _year: '年',
    dayOneOrder: '天/次',
    rules: {
      tableCode: '请选择表单名称',
      taskType: '请选择任务周期',
      highNum: '请输入高风险',
      lowNum: '请输入非高风险',
      otherNum: '请输入临时任务周期',
      timeRange: '请选择临时日期',
    },
    pleaseSelectData: '请选择执行对象',
  },

  /* 风险属地配置--部分 */
  handleRenewBtn: '更新风险属地',
  handleAddBtn: '新增风险属地',
  sort: '排序',
  sortholder: '默认填充自增',
  riskCountryName: '国别',
  riskCountryholder: '请选择国别,可模糊查询',
  riskRegionName: '州/省',
  riskRegionholder: '请想选择国别在选择州/省,可模糊查询',
  universe: '全域',
}
